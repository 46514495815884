import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FaWhatsapp } from 'react-icons/fa';
import {
  CommonSubCategorySection,
} from "../Perfumes";
import Slider from "../Home/Slider";
import {
  YellowOutlineCard,
  BlueOutlineCard,
  CommonItemRow,
  TechManiaBrands,
  SubcategoryProductList,
  InfinIteScrollItems,
} from "../SaverZone";

const SaverZoneSection = ({ data, type, background }) => {
  const border = "2px solid #f6af1c";
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

  const phoneNumber = '+96879361181';
  const openWhatsAppChat = () => {
    window.open("https://api.whatsapp.com/send?phone=96879361181", "_blank"); 
  };
  const iconSize = 32;

  return (
    <div className="categoryPage saverZone perfume-p">
      <div className="maxWidthContainerFluid paddingContainerFluid container-fluid">
        {data.slider_images.length > 0 && (
          <Slider carouselList={data.slider_images} />
        )}
      </div>
      <button onClick={openWhatsAppChat} className="whatsapp-icn"> <FaWhatsapp size={iconSize} /> </button>


{data.other_section.map((ele, index) => {

if (ele.type == 8) {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid mt-2"
    >
      <div className="subCategoryTitle" style={{ color: "#c90101" }}>
        {ele.heading}
      </div>
      <YellowOutlineCard data={ele.items} border={border} />
    </Container>
  );
}

if (ele.type == 1) {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid mt-5 mb-4"
    >
      {/* <div className="subCategoryTitle" style={{ color: "#c90101" }}>
      {ele.heading}
    </div> */}
    <div>
      <CommonSubCategorySection
        data={ele.items}
        // borderRadius={borderRadius}
        type={"perfume"}
      />
    </div>
    </Container>
  );
}

if (ele.type == 9) {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid mt-2 p-combodeal"
    >
      <div className="subCategoryTitle" >
        {ele.heading}
      </div>
      <BlueOutlineCard data={ele.items} border={"0px solid #fff"} />
    </Container>
  );
}
if (ele.type == "brands") {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid mt-2 saverbrand perfumebrand"
    >
      <div className="subCategoryTitle" style={{ color: "#c90101" }}>
        {ele.heading}
      </div>
      <TechManiaBrands data={ele.items} />
    </Container>
  );
}
if (ele.type == "items") {
  return (
    <React.Fragment key={index}>
      <div style={{ background: "#f8eaff" }}>
        <Container
          fluid
          className="maxWidthContainerFluid mt-3 pt-4 pb-4"
        >
          <CommonItemRow type="non-infinite" items={ele.items} />
        </Container>
      </div>
    </React.Fragment>
  );
}
if (ele.type == "single_image") {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid my-4"
    >
      <NavLink to={ele.images.url}>
        {isMobile ? (
          <img
            src={ele.images.mobileImage}
            style={{ width: "100%" }}
            alt=""
          />
        ) : (
          <img
            src={ele.images.desktopImage}
            style={{ width: "100%" }}
            alt=""
          />
        )}
      </NavLink>
    </Container>
  );
}
if (ele.type == "multiple_images") {
  return (
    <Container
      key={index}
      fluid
      className="maxWidthContainerFluid mt-5 mb-3 multipleImage"
    >
      <div className="multi-title">{ele.heading}</div>
      <div className="row mt-4">
        {ele.images.length > 0 &&
          ele.images.map((img, ind) => (
            <div className={`${img.list_css}`}  key={ind + index}>
              <NavLink
                className="text-decoration-none commonCardLink"
                to={img.url}
              >
                {isMobile ? (
                  <img
                    src={img.desktopImage}
                    style={{ width: "100%" }}
                    alt="multiple"
                  />
                ) : (
                  <img
                    src={img.desktopImage}
                    style={{ width: "100%" }}
                    alt="multiple"
                  />
                )}
              </NavLink>
            </div>
          ))}
      </div>
    </Container>
  );
}
if (ele.type == "category_items") {
  return (
    <Container fluid className="maxWidthContainerFluid" key={index}>
      <SubcategoryProductList
        data={ele.items}
        type={"saverZone"}
        background={background}
      />
    </Container>
  );
}
})}

{!["141", "180", "209"].includes(type) && (
        <Container fluid className="maxWidthContainerFluid my-4">
          <InfinIteScrollItems type={type} />
        </Container>
)}

</div>
);
};

export default SaverZoneSection;
