import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { HiOutlineClock } from "react-icons/hi";
import { trackBannerClick } from "../../utils/datalayerPush";
import { useMediaQuery } from "react-responsive";

const DealDaySaver = ({ heading, backgroundImage, data, setWidth, redirect }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {`${hours.toString().padStart(2, "0")}h : ${minutes
        .toString()
        .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s`}
    </span>
  );

  return (
    <div className="dealSection mb-3" style={{ background: `${backgroundImage}` }}>
      <div className="dealOfDayCont" style={{ paddingBottom: "16px" }}>
        <div className="divLargeTitle font-md-sm fw-bold font-syncopate dealOfDay">{heading}</div>
        <NavLink to={redirect}>
          <div className="view_all" style={{color: "#191B1C"}}>{t("global.viewAll")}</div>
        </NavLink>
      </div>
      <Row className="mx-0">
        {data.map((each, i) => (
          <Col xs={6} key={i} style={{padding: '8px'}}>
            <NavLink to={`/details/${each.url}`} className="text-decoration-none">
              <Card className="commoncategoryItems" style={{
                background: '#FFFFFF',
                borderRadius: '12px',
                border: 'none',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                margin: '0 auto'
              }}>
                <div style={{
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '75%', // 4:3 aspect ratio
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${each.image.replaceAll(" ", "%20")})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#FFFFFF'
                  }}></div>
                </div>
                {each.countdown && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '6px',
                    color: '#FF3B30',
                    fontSize: isMobile ? '12px' : '14px',
                    fontWeight: '500',
                    backgroundColor: '#FFF5F5',
                    padding: isMobile ? '4px 8px' : '6px 12px',
                    borderRadius: '6px',
                    margin: '8px',
                    marginBottom: '0'
                  }}>
                    <HiOutlineClock size={isMobile ? 14 : 16} />
                    <Countdown
                      date={each.countdown}
                      renderer={renderer}
                    />
                  </div>
                )}
                <div style={{padding: isMobile ? '8px 12px' : '12px 16px'}}>
                  <div style={{
                    fontSize: isMobile ? '12px' : '14px',
                    fontWeight: '500',
                    color: '#191B1C',
                    marginBottom: '8px',
                    minHeight: isMobile ? '36px' : '42px',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}>
                    {each.name}
                  </div>
                  <div>
                    <div style={{
                      fontSize: isMobile ? '16px' : '18px',
                      fontWeight: '600',
                      color: '#191B1C',
                      whiteSpace: 'nowrap',
                      marginBottom: '4px'
                    }}>
                      <span style={{fontSize: isMobile ? '12px' : '13px', marginRight: '2px'}}>OMR</span>
                      {each.display_price.slice(4)}
                    </div>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      flexWrap: 'nowrap'
                    }}>
                      <span style={{
                        fontSize: isMobile ? '11px' : '13px',
                        color: '#666',
                        textDecoration: 'line-through',
                        whiteSpace: 'nowrap'
                      }}>
                        {each.old_price.replace("OMR ", "")}
                      </span>
                      {each.percentage && (
                        <span style={{
                          fontSize: isMobile ? '10px' : '12px',
                          fontWeight: '500',
                          color: '#4CAF50',
                          backgroundColor: '#E8F5E9',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          whiteSpace: 'nowrap'
                        }}>
                          {each.percentage}% {t("global.off")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DealDaySaver;
